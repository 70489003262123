module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://kaylarconstruction.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/Kaylar-icon.png","name":"Kaylar Construction","short_name":"Kaylar Construction","description":"Discover top-quality general contracting services in Sacramento. From kitchen and bathroom remodeling to siding, windows & doors, decks & patios, and interior design services, we offer comprehensive solutions for your home improvement needs. Trust our skilled team to transform your space with attention to detail, exceptional craftsmanship, and personalized customer service. Contact us today for a consultation and let us bring your home improvement dreams to life.","lang":"en","display":"browser","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5af9382ea5514c503c0c9c2fa77fd0d5"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Didact Gothic","file":"https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Syne:wght@400;500;600;700;800&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-120,"duration":1000},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
